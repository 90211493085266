/* global plausible */
import React, { useEffect, useState } from "react";

import Helmet from "./components/Helmet/Helmet";
import LandingPage from "./pages/LandingPage/LandingPage.jsx";
import SearchPage from "./pages/SearchPage/SearchPage.jsx";

function App() {
  const route = window.location.pathname;
  const tldInfoPageExtension = route
    .match(/tld\/(?<ext>.*)/)
    ?.groups?.ext?.replace("/", "");
  const urlExtension = new URLSearchParams(window.location.search)?.get("q");

  const [initialSearch, setInitialSearch] = useState(
    (tldInfoPageExtension && `.${tldInfoPageExtension}`) || urlExtension || ""
  );

  let [showSearch, setShowSearch] = useState();
  useEffect(() => {
    // set initial state before first render, for proper SSR rehydration
    const initialSearchActive =
      !!tldInfoPageExtension ||
      !!urlExtension ||
      window.location.search.startsWith("?q=");
    setShowSearch(initialSearchActive);
  }, [tldInfoPageExtension, urlExtension]);

  const isReturningVisit = !!localStorage.getItem("favoriteDomains");

  return (
    <>
      <Helmet />
      {!showSearch ? (
        <LandingPage
          onSearch={(search) => {
            window.history.pushState(null, null, `?q=${search}`);
            setInitialSearch(search);
            setShowSearch(true);
            trackLandingPageSearch(search, isReturningVisit);
          }}
          isReturningVisit={isReturningVisit}
        />
      ) : (
        <SearchPage
          initialSearch={initialSearch}
          showHome={() => {
            window.history.pushState(null, null, "/");
            setInitialSearch("");
            setShowSearch(false);
          }}
        />
      )}
    </>
  );
}

function trackLandingPageSearch(search, isReturningVisit) {
  try {
    plausible("landingPageSearch", {
      props: {
        returningVisit: isReturningVisit,
        isExploreClick: search === "",
      },
    });
  } catch {}
}

export default App;
