import React, { useEffect } from "react";

import styles from "./NewsletterTutorial.module.scss";

export default function NewsletterTutorial({ exitTutorial }) {
  // track substack signup engagement
  useEffect(() => {
    const handleBlur = function (e) {
      if (
        document.activeElement === document.getElementById("substack-iframe")
      ) {
        try {
          window.plausible("newsletterSignupEngagement", {
            props: { fromSignupTutorial: true },
          });
        } catch {}
      }
    };
    window.addEventListener("blur", handleBlur);
    return () => {
      window.removeEventListener("blur", handleBlur);
    };
  });

  return (
    <div className={[styles.tutorial, styles.active].join(" ")}>
      <div className={styles.text} onClick={(e) => exitTutorial(e, true)}>
        Like the idea? Have feedback?
        <br />
        <br />
        Try out new name suggestion features by signing up for updates!
      </div>
      <div className={styles.newsletterContainer}>
        <iframe
          src="https://domaingarden.substack.com/embed"
          width="100%"
          height="100%"
          style={{
            background: "transparent",
            border: "2px solid #42e360",
            borderRadius: "12px",
            boxShadow: "0 0 5px #bcccdc",
          }}
          frameBorder="0"
          scrolling="no"
          id="substack-iframe"
          title="newsletter-singup-tutorial"
        ></iframe>
      </div>
      <div
        className={styles.background}
        onClick={(e) => exitTutorial(e, true)}
      ></div>
    </div>
  );
}
