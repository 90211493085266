import React from "react";

import styles from "./DomainGroup.module.scss";
import Domain from "../Domain/Domain.jsx";

const DomainGroup = React.forwardRef(
  (
    {
      title,
      extensions,
      taken,
      search,
      updatedDates,
      premiumInfo,
      setCardTrackedActiveState,
      deepCheckDomain,
      deepQueried,
      preciseQueryTld,
      favoriteDomains,
      setDomainFavoriteState,
      style,
      tutorialState,
      exitTutorial,
      popularDomainsState,
    },
    ref
  ) => {
    return (
      <div className={styles.container} ref={ref} style={style}>
        <h2 className={styles.title}>
          <span>{title}</span>
        </h2>
        <div className={styles.items}>
          {extensions.map((ext) => {
            const domain = search + "." + ext;
            return (
              <Domain
                extension={ext}
                preciseQueried={preciseQueryTld === ext}
                taken={search !== "" && taken[ext]}
                updatedDate={updatedDates[ext]}
                premiumInfo={premiumInfo[ext]}
                name={search}
                key={ext}
                setCardTrackedActiveState={(state) =>
                  setCardTrackedActiveState(ext, state)
                }
                deepCheckDomain={() => deepCheckDomain(ext)}
                deepQueried={search === "" || deepQueried[domain]}
                isFavorite={favoriteDomains.includes(domain)}
                setFavorite={(state) => setDomainFavoriteState(domain, state)}
                tutorialState={tutorialState}
                exitTutorial={exitTutorial}
                popularDomainsState={popularDomainsState?.[ext]}
              />
            );
          })}
        </div>
      </div>
    );
  }
);

export default React.memo(DomainGroup);
