/* global plausible */
import React, { useState } from "react";

import ClipLoader from "react-spinners/ClipLoader";
import { ReactComponent as UnfilledStar } from "../../../icons/star-regular.svg";
import { ReactComponent as FilledStar } from "../../../icons/star-solid.svg";
import { ReactComponent as WikipediaIcon } from "../../../icons/wikipedia-w-brands.svg";
import FavouritesTutorial from "../Tutorial/FavouritesTutorial";
import ExtensionInfoTutorial from "../Tutorial/ExtensionInfoTutorial";
import AvailableContent from "./AvailableContent";
import TakenContent from "./TakenContent";
import ExtensionInfoContent from "./ExtensionInfoContent";

import styles from "./Card.module.scss";

export default function Card({
  query,
  tld,
  extensionDisplayString,
  isAvailable,
  updatedDate,
  premiumInfo,
  deepQueried,
  cardToLeft,
  isFavorite,
  setFavorite,
  style = {},
  tutorialState,
  exitTutorial,
  rightCardSpace,
  setCardActive,
  popularDomainsState,
  notifyExploreSwitch,
}) {
  const domain = query + "." + extensionDisplayString;

  const setFavoriteTracked = (event, added) => {
    setFavorite(added);
    setCardActive(false); // exit hover as re-render breaks onMouseLeave on mobile
    if (added) {
      if (tutorialState.active === "favorites") {
        exitTutorial(event, false);
      }

      plausible("addFavorite");
    }

    event.stopPropagation(); // prevent onClick card handler
  };

  const [extensionInfoActive, setExtensionInfoActive] = useState(query === "");
  function titleClick() {
    if (query !== "") {
      setExtensionInfoActive(!extensionInfoActive);
      notifyExploreSwitch();
    }
  }

  return (
    <div
      style={style}
      className={[
        styles.card,
        cardToLeft ? styles.cardToLeft : "",
        isAvailable ? styles.isAvailable : "",
        isFavorite ? styles.isFavorite : "",
        extensionInfoActive ? styles.extensionInfoActive : "",
      ].join(" ")}
    >
      <div
        className={[
          styles.titleBar,
          isFavorite ? styles.isFavorite : "",
          query === "" ? styles.isTldInfoCard : "",
        ].join(" ")}
      >
        {isAvailable ? (
          <span className={[styles.text, styles.available].join(" ")}>
            {!extensionInfoActive && (
              <span className={styles.query}>{query}</span>
            )}
            <span
              className={[
                styles.extension,
                tutorialState.active === "extensionInfo"
                  ? styles.extensionInfoTutorialActive
                  : "",
              ].join(" ")}
              onClick={titleClick}
            >
              {tutorialState.active === "extensionInfo" &&
                tutorialState.extensionInfo.step === 1 && (
                  <ExtensionInfoTutorial
                    exitTutorial={exitTutorial}
                    rightCardSpace={rightCardSpace}
                    isStep1={true}
                  />
                )}
              {"." + extensionDisplayString}
            </span>
            {!extensionInfoActive && deepQueried && (
              <div className={styles.starContainer}>
                {isFavorite ? (
                  <FilledStar
                    id="star"
                    className={[
                      styles.star,
                      styles.isFavorite,
                      tutorialState.active === "favorites"
                        ? styles.favoritesTutorialActive
                        : "",
                    ].join(" ")}
                    onClick={(event) => setFavoriteTracked(event, false)}
                  />
                ) : (
                  <UnfilledStar
                    id="star"
                    className={[
                      styles.star,
                      tutorialState.active === "favorites"
                        ? styles.favoritesTutorialActive
                        : "",
                    ].join(" ")}
                    onClick={(event) => {
                      setFavoriteTracked(event, true);
                    }}
                  />
                )}
                {tutorialState.active === "favorites" && (
                  <FavouritesTutorial
                    exitTutorial={exitTutorial}
                    rightCardSpace={rightCardSpace}
                  />
                )}
              </div>
            )}
            {!extensionInfoActive && !deepQueried && isAvailable && (
              <span className={styles.spinner}>
                <ClipLoader
                  css={{ marginLeft: "4px", verticalAlign: "-3px" }}
                  size={20}
                  //size={"150px"} this also works
                  color={"#3e4c59"}
                  loading={true}
                />
              </span>
            )}
          </span>
        ) : (
          <a
            href={"https://" + domain}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.text}
          >
            {domain}
          </a>
        )}
        {extensionInfoActive && (
          <>
            <a
              href={`https://icannwiki.org/.${tld}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <WikipediaIcon className={styles.infoIcon} />
            </a>
          </>
        )}
      </div>

      {!extensionInfoActive ? (
        <div className={styles.content}>
          {isAvailable ? (
            <AvailableContent
              base={query}
              tld={tld}
              domain={domain}
              premiumInfo={premiumInfo}
            />
          ) : (
            <TakenContent updatedDate={updatedDate} />
          )}
        </div>
      ) : (
        <ExtensionInfoContent
          tld={tld}
          // forceWidth={cardRef.current?.offsetWidth}
          popularDomainsState={popularDomainsState}
        />
      )}
    </div>
  );
}
