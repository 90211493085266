import React, { useEffect, useState } from "react";
import { debounce } from "lodash";

import styles from "./SmartDomainGroup.module.scss";
import Domain from "../Domain/Domain.jsx";
import * as api from "../../../api.js";

async function fetchSuggestedDomains(calledSearch, resultsCallback) {
  const domains = await api.getSuggestedDomains(calledSearch);

  resultsCallback(domains, calledSearch);
}
const fetchSuggestedDomainsDebounced = debounce(fetchSuggestedDomains, 100);

let currentSearch = null;
const SmartDomainGroup = React.forwardRef(
  (
    {
      search,
      updatedDates,
      premiumInfo,
      setCardTrackedActiveState,
      deepCheckDomain,
      deepQueried,
      preciseQueryTld,
      favoriteDomains,
      setDomainFavoriteState,
      style,
      tutorialState,
      exitTutorial,
      popularDomainsState,
      maxNumOfResults,
    },
    ref
  ) => {
    const [extensions, setExtensions] = useState([]);

    useEffect(() => {
      if (search === "") {
        setExtensions([]);
        return;
      }

      currentSearch = search;
      async function resultsCallback(domains, calledSearch) {
        if (calledSearch !== currentSearch) {
          // changed query while querying
          return;
        }
        // console.log(calledSearch, currentSearch, domains);
        setExtensions(domains.slice(0, maxNumOfResults - 1));
      }
      // console.log("effect", search);
      fetchSuggestedDomainsDebounced(search, resultsCallback);
    }, [search, maxNumOfResults]);

    return (
      <div className={styles.container} ref={ref} style={style}>
        <h2 className={styles.title}>
          <span>
            Suggested{" "}
            <span role="img" aria-label="sparkles">
              ✨
            </span>
          </span>
        </h2>
        {search === "" ? (
          <div className={styles.exploreText}>
            Search for a name to see suggestions
          </div>
        ) : (
          <div className={styles.items}>
            {extensions.map((ext) => {
              const domain = search + "." + ext;
              return (
                <Domain
                  extension={ext}
                  preciseQueried={preciseQueryTld === ext}
                  taken={false} // donuts API should only return unregistered domains
                  updatedDate={updatedDates[ext]}
                  premiumInfo={premiumInfo[ext]}
                  name={search}
                  key={ext}
                  setCardTrackedActiveState={(state) =>
                    setCardTrackedActiveState(ext, state)
                  }
                  deepCheckDomain={() => deepCheckDomain(ext)}
                  deepQueried={search === "" || deepQueried[domain]}
                  isFavorite={favoriteDomains.includes(domain)}
                  setFavorite={(state) => setDomainFavoriteState(domain, state)}
                  tutorialState={tutorialState}
                  exitTutorial={exitTutorial}
                  popularDomainsState={popularDomainsState?.[ext]}
                />
              );
            })}
          </div>
        )}
      </div>
    );
  }
);

export default SmartDomainGroup;
