/* global plausible */
import React, { useState, useRef, useEffect } from "react";
import punycode from "punycode";

import Card from "../Card/Card";
import { ReactComponent as FilledStar } from "../../../icons/star-solid.svg";

import styles from "./Domain.module.scss";
import pricing_data from "../../../../src/data/pricing.json";

let hoverCount = 0;
function trackHover(extension) {
  hoverCount++;
  try {
    plausible("hoverDomainCard", {
      props: { sessionCount: hoverCount, extension },
    });
  } catch {}
}

let hoverExploreCount = 0;
function trackExploreHover(fromTitleClick = false, extension) {
  hoverExploreCount++;
  try {
    plausible("hoverExploreCard", {
      props: {
        sessionCount: hoverExploreCount,
        fromTitleClick: fromTitleClick,
        extension,
      },
    });
  } catch {}
}

let startHover = null;

const Domain = ({
  extension,
  taken,
  name,
  updatedDate,
  premiumInfo,
  setCardTrackedActiveState,
  deepCheckDomain,
  deepQueried,
  isFavorite,
  setFavorite,
  preciseQueried = false,
  showFullDomainOnPreview = false,
  showFavoriteIconOnPreview = true,
  className = null,
  cardToLeft = null,
  tutorialState = {},
  exitTutorial,
  popularDomainsState,
}) => {
  // decode international encoding
  // ignore lint deprecation warning, not using the Node.js module
  const extensionDisplayString = extension.startsWith("xn--")
    ? punycode.toUnicode(extension)
    : extension;

  const [showCard, setCardActive] = useState(false);
  useEffect(() => {
    setCardActive(preciseQueried);
  }, [preciseQueried]);

  if (preciseQueried && !deepQueried) {
    deepCheckDomain();
  }

  const onHover = () => {
    setCardActive(true);
    setCardTrackedActiveState(true);

    // deep query only for available
    if (!taken && !deepQueried) {
      deepCheckDomain();
    }

    startHover = new Date().getTime();
  };

  const onHoverExit = () => {
    if (tutorialState.active) {
      // tutorial will take focus, so prevent mouse leaving
      trackHover(extension);
      return;
    }
    setCardActive(false);
    setCardTrackedActiveState(false);

    const endHover = new Date().getTime();
    const hoverSeconds = (endHover - startHover) / 1000;
    if (hoverSeconds > 0.7) {
      if (name !== "") {
        trackHover(extension);
      } else {
        trackExploreHover(false, extension);
      }
    }
  };

  const exitTutorialInternal = (event, leaveCardHover) => {
    exitTutorial();

    // if (
    //   (leaveCardHover && tutorialState.active !== "extensionInfo") ||
    //   tutorialState.extensionInfo.step === 2
    // ) {
    if (leaveCardHover) {
      setCardActive(false);
      setCardTrackedActiveState(false);
    }

    event.stopPropagation(); // will set card active again otherwise, as the tutorial is part of the card root
  };

  function notifyExploreSwitch() {
    trackExploreHover(true, extension);

    if (
      tutorialState.active === "extensionInfo" &&
      tutorialState.extensionInfo.step === 1
    ) {
      exitTutorial(); // increase tutorial step
    }
  }

  const windowWidth = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  // whether to show the detail card to the left or right side on small viewports
  const positionRef = useRef();
  let rightCardSpace;
  if (cardToLeft === null) {
    cardToLeft = false;
    if (positionRef.current) {
      rightCardSpace = windowWidth - positionRef.current.offsetLeft;

      if (windowWidth > rightCardSpace * 4) {
        cardToLeft = rightCardSpace < 600;
      } else {
        // small viewport
        cardToLeft = rightCardSpace < 280;
      }
    }
  }

  // for very small viewports, move card to closest edge
  let isMobile = false;
  let mobileCardStyling = {};
  if (positionRef.current && windowWidth < 500) {
    isMobile = true;

    if (cardToLeft) {
      mobileCardStyling = {
        right: `calc(-${
          windowWidth -
          positionRef.current.offsetLeft -
          positionRef.current.offsetWidth
        }px + 4px)`,
      };
    } else {
      mobileCardStyling = {
        left: `calc(-${positionRef.current.offsetLeft}px + 4px)`,
      };
    }
  }

  const prices = pricing_data.tlds[extension];
  const showCheapPrice =
    name !== "" &&
    prices?.[0] &&
    prices[0].register <= 5 &&
    !premiumInfo?.isPremium & !taken;
  // const isExpensive = !taken && prices?.[0]?.register > 200;

  const isViral = !taken && popularDomainsState?.stats?.isViral;

  return (
    <>
      <div
        className={[
          styles.container,
          taken && styles.taken,
          preciseQueried && styles.preciseQueried,
          premiumInfo?.isPremium && styles.isPremium,
          showCheapPrice && styles.hasSale,
          className || "",
        ].join(" ")}
        onMouseEnter={onHover}
        onMouseLeave={onHoverExit}
        onClick={onHover}
        ref={positionRef}
      >
        {showCard && (
          <Card
            isAvailable={!taken}
            query={name}
            extensionDisplayString={extensionDisplayString}
            tld={extension}
            updatedDate={updatedDate}
            premiumInfo={premiumInfo}
            deepQueried={deepQueried}
            onMouseLeave={onHoverExit}
            setCardActive={setCardActive}
            cardToLeft={cardToLeft}
            isFavorite={isFavorite}
            setFavorite={setFavorite}
            style={mobileCardStyling}
            tutorialState={tutorialState}
            exitTutorial={exitTutorialInternal}
            rightCardSpace={rightCardSpace}
            popularDomainsState={popularDomainsState}
            notifyExploreSwitch={notifyExploreSwitch}
          />
        )}

        <div
          className={[
            styles.title,
            extensionDisplayString.length > 7 && styles.longName,
            extensionDisplayString.length > 12 && styles.veryLongName,
          ].join(" ")}
        >
          {showFullDomainOnPreview ? name : ""}.{extensionDisplayString}
        </div>
        <div className={styles.icons}>
          {isFavorite && showFavoriteIconOnPreview ? (
            <FilledStar
              className={[styles.star]}
              onClick={() => {
                if (!isMobile) {
                  setFavorite(false);
                }
              }}
            />
          ) : (
            <>
              {/* {isExpensive ? (
                <div className={styles.emoji}>
                  <span role="img" aria-label="expensive">
                    💎
                  </span>
                </div>
              ) : (
                <></>
              )} */}
              {isViral ? (
                <div className={styles.emoji}>
                  <span role="img" aria-label="popular">
                    📈
                  </span>
                </div>
              ) : (
                <></>
              )}
              {showCheapPrice ? (
                <div className={styles.salePrice}>
                  ${Math.round(prices[0].register)}
                </div>
              ) : (
                <></>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default React.memo(Domain);
