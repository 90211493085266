/* global plausible */
import React, { useEffect } from "react";

import styles from "./about.module.scss";

function About() {
  // track substack signup engagement
  useEffect(() => {
    const handleBlur = function (e) {
      if (
        document.activeElement === document.getElementById("substack-iframe")
      ) {
        try {
          plausible("newsletterSignupEngagement");
        } catch {}
      }
    };
    window.addEventListener("blur", handleBlur);
    return () => {
      window.removeEventListener("blur", handleBlur);
    };
  });

  return (
    <div className={styles.container}>
      <div>
        <span className={styles.tldsDomain}>domain.garden</span> helps you to
        find great, clever domain names for your new idea, side project or
        business.
      </div>
      <div>
        Skip .com and build your brand using the 1000+ new domain extensions,
        like
        <a
          className={styles.domain}
          href="https://crisp.chat"
          target="_blank"
          rel="noopener noreferrer"
        >
          crisp.chat
        </a>
        ,
        <a
          className={styles.domain}
          href="https://frame.work"
          target="_blank"
          rel="noopener noreferrer"
        >
          frame.work
        </a>
        ,
        <a
          className={styles.domain}
          href="https://magic.link"
          target="_blank"
          rel="noopener noreferrer"
        >
          magic.link
        </a>
        ,
        <a
          className={styles.domain}
          href="https://pudding.cool"
          target="_blank"
          rel="noopener noreferrer"
        >
          pudding.cool
        </a>{" "}
        or
        <a
          className={styles.domain}
          href="https://levels.fyi"
          target="_blank"
          rel="noopener noreferrer"
        >
          levels.fyi
        </a>
        . Using new domain extensions{" "}
        <a
          className={styles.link}
          href="https://domains.google/learn/how-to-choose-traditional-vs-new-domain-endings"
          target="_blank"
          rel="noopener noreferrer"
        >
          does not impact search rankings
        </a>
        .
      </div>
      <div>
        Sorry for the search downtime in December 2023! I haven't updated the website in a few years and things broke. Hopefully I can ship a few improvements in January too.
      </div>
      <div>
      <a
          className={styles.domain}
          href="https://lindylearn.io"
          target="_blank"
          rel="noopener noreferrer"
        >
          Peter Hagen
        </a>, 2023.
      </div>
      {/* <div>
        We're working on actually useful domain suggestions! Be the first to
        hear about new features, and make the site more useful for you by
        signing up for updates below.
      </div>
      <div>Let's build a better domain name search together!</div>
      <div className={styles.newsletterContainer}>
        <iframe
          src="https://domaingarden.substack.com/embed"
          width="100%"
          height="130"
          style={{ background: "#f0f4f8" }}
          frameBorder="0"
          scrolling="no"
          id="substack-iframe"
          title="newsletter-signup-about"
        ></iframe>
      </div> */}
    </div>
  );
}

export default About;
