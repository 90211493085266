import React from "react";

import styles from "./Card.module.scss";

const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export default function TakenContent({ updatedDate }) {
  let dateString = "";
  if (updatedDate) {
    const date = new Date(updatedDate);
    const elapsedMilliseconds = Date.now() - date.getTime();
    const days = elapsedMilliseconds / 1000 / 60 / 60 / 24;

    if (days <= 365) {
      if (days < 1) {
        dateString = `today`;
      } else if (Math.round(days) === 1) {
        dateString = `yesterday`;
      } else if (days < 30) {
        dateString = `${Math.round(days)} days ago`;
      } else if (Math.round(days / 30 === 1)) {
        dateString = `1 month ago`;
      } else {
        dateString = Math.round(days / 30) + ` months ago`;
      }
    } else {
      dateString = monthNames[date.getMonth()] + " " + date.getFullYear();
    }
  }

  return (
    <div className={styles.takenContent}>
      {!dateString ? "already " : ""}registered {dateString}
    </div>
  );
}
