import React, { useRef, useEffect } from "react";

import About from "./About";
import styles from "./LandingPage.module.scss";
import { ReactComponent as Arrow } from "../../icons/landingpage-arrow.svg";

import { ReactComponent as LargeLogo } from "../../icons/logo_with_title_nofont.svg";

function LandingPage({ onSearch, isReturningVisit }) {
  const inputEl = useRef(null);

  useEffect(() => {
    inputEl.current.focus();
  }, []);
  useEffect(() => window.scrollTo(0, 0), []);

  // useEffect(() => {
  //   if (navigator.userAgent !== "ReactSnap") {
  //     // only render for user, to avoid breaking onClick handlers
  //     window._changeloglife_init("domaingarden", "#changelog_life");
  //   }
  // }, []);
  
  return (
    <>
      <div className={styles.info}>
        <div className={styles.logo}>
          <LargeLogo alt="domain.garden" />
          <h1 style={{ display: "none" }}>
            domain.garden - domain name search engine & Price Comparison
          </h1>
        </div>

        <h2 className={styles.subtext}>
          <div>
            find creative names for your next idea!
            {/* <div className={styles.changelogDot}>
              <div id="changelog_life" />
            </div> */}
          </div>
        </h2>
      </div>
      <div className={styles.search}>
        <input
          className={styles.input}
          placeholder="newdomain.name"
          onChange={(event) => onSearch(event.target.value.toLowerCase())}
          autoFocus
          type="text"
          autoComplete="off"
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          ref={inputEl}
        />
        <div className={styles.exploreButton}>
          <div className={styles.orText}>or</div>
          <span
            className={styles.exploreText}
            onClick={() => onSearch("")}
            title="Explore domain extensions"
          >
            explore extensions
          </span>

          <Arrow className={styles.arrow} />
          <div className={styles.arrowText}>check out popular examples</div>
        </div>
      </div>

      <div className={styles.bottom}>
        <div
          className={styles.about}
          onClick={(event) =>
            event.target.scrollIntoView({ behavior: "smooth" })
          }
        >
          <span>motivation</span>
        </div>
      </div>
      <div className={styles.below}>
        <About />
      </div>
    </>
  );
}

export default LandingPage;
