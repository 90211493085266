import React from "react";
import punycode from "punycode";
import { Helmet as ReactHelmet } from "react-helmet";

function Helmet({ activeTld = null, popularExample = null }) {
  if (!activeTld) {
    return (
      <HeaderTags
        title="Domain Name Search | Instant Availability Check | TLD List | Price Comparison"
        description="Instantly search across 1000+ new domain name extensions. Generate clever names, compare registry prices, and list popular examples per TLD."
      />
    );
  }

  const extensionDisplayString = activeTld.startsWith("xn--")
    ? punycode.toUnicode(activeTld)
    : activeTld;

  const popularExampleSection = popularExample ? ` like ${popularExample}` : ``;
  return (
    <HeaderTags
      title={`Cheap .${extensionDisplayString} domain search | Price Comparison | Check Availability | Popular Examples`}
      description={`Compare .${extensionDisplayString} registry prices and check domain availability. Find creative names${popularExampleSection}, for the cheapest price. Or search for 1000+ other TLDs.`}
    />
  );
}

function HeaderTags({ title, description }) {
  return (
    <ReactHelmet>
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta name="og:title" content={title} />
      <meta property="twitter:title" content={title} />

      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
      <meta property="twitter:description" content={description} />
    </ReactHelmet>
  );
}

export default Helmet;
