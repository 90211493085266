import React from "react";

import styles from "./NameStats.module.scss";
import numTotalExtensions from "../../../data/numTotalCoveredExtensions.json"; // import statically for performance

function NameStats({ numTakenForActiveQuery }) {
  return (
    <div className={styles.root}>
      {numTakenForActiveQuery !== null && (
        <>
          <span
            className={styles.current}
            title="Already registered domains for this query"
          >
            {numTakenForActiveQuery || 0}
          </span>
          <span className={styles.separation}>/</span>
          <span
            className={styles.total}
            title="Total number of domain extensions"
          >
            {numTotalExtensions}
          </span>
        </>
      )}
    </div>
  );
}

export default NameStats;
