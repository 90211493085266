import React, { Component } from "react";

import styles from "./FavoritesWindow.module.scss";
import Domain from "../../../pages/SearchPage/Domain/Domain";
import * as api from "../../../api.js";

import pricing_data from "../../../../src/data/pricing.json";

export default class FavoritesWindow extends Component {
  state = {
    deepChecked: {},
    taken: {},
    updated: {},
    premiumInfo: {},
  };

  componentDidMount() {
    // this.props.favoriteDomains.map(this.deepQueryDomain.bind(this));
  }

  componentDidUpdate(prevProps, newProps) {
    if (prevProps.favoriteDomains !== this.props.favoriteDomains) {
      // update favorite status
      // const newDomains = newProps.favoriteDomains.filter((domain) =>
      //   prevProps.favoriteDomains.includes(domain)
      // );
      // console.log(newDomains);
      // newDomains.map(this.deepQueryDomain.bind(this));
      this.props.favoriteDomains.map(this.deepQueryDomain.bind(this));
    }
  }

  deepQueryDomain(domain) {
    const [name, extension] = domain.split(".");

    api
      .deepCheckDomain(name, extension)
      .then((data) => {
        this.setState({
          deepChecked: {
            ...this.state.deepChecked,
            [domain]: true,
          },
          taken: {
            ...this.state.taken,
            [domain]: data.taken,
          },
          updated: {
            ...this.state.updated,
            [domain]: this.state.updated[domain] || data.created,
          },
          premiumInfo: {
            ...this.state.premiumInfo,
            [domain]: {
              isPremium: data.isPremium,
              prices: data.prices,
            },
          },
        });
      })
      .catch((err) => {
        console.error("Failed to deep query favorite domain", domain, err);
        // still end the querying state
        this.setState({
          deepChecked: {
            ...this.state.deepChecked,
            [domain]: true,
          },
        });
      });
  }

  render() {
    return (
      <div onMouseLeave={this.props.onMouseLeave.bind(this)}>
        <div
          className={[styles.favoritesWindow, this.props.className].join(" ")}
        >
          <div className={styles.title}>Saved Domains</div>
          <div className={[styles.table].join(" ")}>
            <div className={styles.headerCell}>domain</div>
            <div className={styles.headerCell}>price</div>

            {this.props.favoriteDomains.map((domain) => {
              const [name, extension] = domain.split(".");

              const isPremium =
                this.state.premiumInfo.isPremium &&
                this.state.premiumInfo.prices;
              let cheapestPremiumRegistry = null;
              if (isPremium) {
                cheapestPremiumRegistry = Object.values(
                  this.state.premiumInfo[domain]?.prices?.registries
                ).sort((a, b) => a.registerPrice - b.registerPrice)?.[0];
              }

              return (
                <React.Fragment key={domain}>
                  <Domain
                    name={name}
                    extension={extension}
                    taken={this.state.taken[domain]}
                    updatedDate={this.state.updated[domain]}
                    premiumInfo={this.state.premiumInfo[domain]}
                    deepCheckDomain={() => this.deepQueryDomain(domain)}
                    setCardTrackedActiveState={() => {}}
                    deepQueried={this.state.deepChecked[domain]}
                    isFavorite={this.props.favoriteDomains.includes(domain)}
                    setFavorite={(state) =>
                      this.props.setDomainFavoriteState(domain, state)
                    }
                    className={styles.domain}
                    showFullDomainOnPreview={true}
                    showFavoriteIconOnPreview={false}
                    cardToLeft={true}
                  />
                  <div
                    className={[
                      styles.cost,
                      styles.register,
                      this.state.premiumInfo[domain]?.isPremium
                        ? styles.premium
                        : "",
                    ].join(" ")}
                    title="1st year price"
                  >
                    {!this.state.taken[domain] &&
                      "$" +
                        Math.round(
                          cheapestPremiumRegistry?.registerPrice ||
                            pricing_data.tlds[extension]?.[0].register
                        )}
                  </div>
                  <div
                    className={[
                      styles.cost,
                      styles.renew,
                      this.state.premiumInfo[domain]?.isPremium
                        ? styles.premium
                        : "",
                    ].join(" ")}
                    title="2nd year price"
                  >
                    {!this.state.taken[domain] &&
                      "$" +
                        Math.round(
                          cheapestPremiumRegistry?.renewalPrice ||
                            pricing_data.tlds[extension]?.[0].renew
                        )}
                  </div>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
