/* global plausible */
import React, { Component } from "react";
import { debounce, memoize } from "lodash";
import { VariableSizeList as List } from "react-window";
import AutoSizer from "react-virtualized-auto-sizer";

import Helmet from "../../components/Helmet/Helmet";
import Searchbar from "../../components/Header/SearchBar/Searchbar";
import DomainGroup from "./DomainGroup/DomainGroup";
import SmartDomainGroup from "./SmartDomainGroup/SmartDomainGroup";
import NewsletterTutorial from "./Tutorial/NewsletterTutorial";
import styles from "./SearchPage.module.scss";
import * as api from "../../api.js";

import domains from "../../data/tlds.json";
import groups from "../../data/tldGroups";

class SearchPage extends Component {
  state = {
    search: "",
    name: "",
    tld: null,
    fetching: false,
    lastResults: {
      taken: {},
      updated: {},
      premiumInfo: {},
      numTaken: null,
    },
    deepChecked: {},
    favoriteDomains: this.getPersistedFavoriteDomains(),
    tutorialState: {
      favorites: {
        alreadyShowed: !!localStorage.getItem("seenFavoritesTutorial"),
      },
      extensionInfo: {
        step: 1,
        alreadyShowed: !!localStorage.getItem("seenExtensionInfoTutorial"),
      },
      newsletterSignup: {
        alreadyShowed: !!localStorage.getItem("seenNewsletterSignupTutorial"),
      },
      curDomainHoverCount: 0,
      active: null,
    },
    popularDomainsState: null,
  };
  activeCardExt = null; // only tracks local component state for performance

  getPersistedFavoriteDomains() {
    const savedJson = localStorage.getItem("favoriteDomains");
    if (!savedJson) {
      // save empty array to mark visit
      localStorage.setItem("favoriteDomains", "[]");
      return [];
    }
    return JSON.parse(savedJson);
  }

  constructor(props) {
    super(props);

    this.state.search = props.initialSearch;

    this.doRequestDebounced = debounce(this.doRequest, 100);
    this.trackSearchDebounced = debounce(this.trackSearch, 1000);

    this.domainGroupListRef = React.createRef();
  }

  componentDidMount() {
    var _this = this;

    if (_this.props.initialSearch.includes(".")) {
      // if inital search includes TLD (would trigger scroll, wait until first render)
      window.requestAnimationFrame(function () {
        _this.onSearch(_this.props.initialSearch);
      });
    } else {
      _this.onSearch(_this.props.initialSearch);
    }

    this.fetchPopularDomainState();
  }

  scrollToGroup(groupName) {
    if (!this.domainGroupListRef.current) {
      return;
    }

    const groupIndex = groups.findIndex((group) => group.title === groupName);
    const groupTopOffset = this.getRowHeights(
      document.documentElement.clientWidth
    )
      .slice(0, groupIndex)
      .reduce((a, b) => a + b, 0);

    this.domainGroupListRef.current.scrollTo(groupTopOffset);
  }

  onSearch(search) {
    if (this.state.tutorialState.active) {
      return;
    }

    window.history.pushState(null, null, `?q=${search}`);

    if (search === "") {
      this.setState({
        search: "",
        name: "",
        tld: null,
        fetching: false,
        lastResults: {
          taken: {},
          updated: {},
          premiumInfo: {},
          numTaken: null,
        },
      });
      return;
    }

    let name, tld;
    const dot = search.indexOf(".");
    if (dot !== -1) {
      name = search.slice(0, dot);
      tld = search.slice(dot + 1, search.length);
    } else {
      name = search;
      tld = null;
    }

    if (name === "") {
      this.setState({
        lastResults: {
          ...this.state.lastResults,
          numTaken: null,
        },
      });
    }

    if (name === this.state.name) {
      // changed tld
      this.setState({
        search,
        tld,
      });
    } else {
      // changed query string
      this.setState({
        search,
        name,
        tld,
        fetching: true,
      });

      this.doRequestDebounced(name);
      this.trackSearchDebounced();
    }

    if (tld && domains.includes(tld)) {
      // find the group the tld belongs in
      for (let i = 0; i < groups.length; i++) {
        const group = groups[i];

        if (group.tlds.includes(tld)) {
          this.scrollToGroup(group.title);
          break;
        }
      }
    }
  }

  doRequest(name) {
    api.requestAvail(name).then((response) => {
      if (name !== this.state.name) {
        return;
      }

      // TODO optimize state mapping performance
      this.setState({
        fetching: false,
        lastResults: {
          taken: Object.keys(response.data.tlds).reduce(
            (acc, tldCode) => ({
              ...acc,
              [domains[tldCode]]: response.data.tlds[tldCode].taken,
            }),
            {}
          ),
          updated: Object.keys(response.data.tlds).reduce(
            (acc, tldCode) => ({
              ...acc,
              [domains[tldCode]]: response.data.tlds[tldCode].updated || "",
            }),
            {}
          ),
          premiumInfo: Object.keys(response.data.tlds).reduce(
            (acc, tldCode) => ({
              ...acc,
              [domains[tldCode]]: {
                isPremium: !!response.data.tlds[tldCode].isPremium,
                prices: response.data.tlds[tldCode].basePrices
                  ? {
                      base: response.data.tlds[tldCode].basePrices,
                    }
                  : null,
              },
            }),
            {}
          ),
          numTaken: Object.values(response.data.tlds).filter(
            (tldInfo) => tldInfo.taken
          ).length,
        },
      });
    });
  }

  debouncedSearchCount = 0;
  trackSearch() {
    this.debouncedSearchCount++;
    try {
      plausible("debouncedSearchQuery", {
        props: { sessionCount: this.debouncedSearchCount },
      });
    } catch {}
  }

  deepCheckCurrentExtension(extension) {
    const domain = this.state.name + "." + extension;

    api
      .deepCheckDomain(this.state.name, extension)
      .then((data) => {
        // TODO re-enable tutorials again
        const countTowardsTutorial = false; //!data.taken && this.activeCardExt !== null;
        const activateExtensionInfoTutorial =
          countTowardsTutorial &&
          !this.state.tutorialState.extensionInfo.alreadyShowed &&
          this.state.tutorialState.curDomainHoverCount + 1 >= 4;

        const activateFavoritesTutorial =
          countTowardsTutorial &&
          !this.state.tutorialState.favorites.alreadyShowed &&
          this.state.tutorialState.curDomainHoverCount + 1 >= 10;

        const activateNewsletterSignupTutorial =
          countTowardsTutorial &&
          !this.state.tutorialState.newsletterSignup.alreadyShowed &&
          this.state.tutorialState.curDomainHoverCount + 1 >= 25;

        // if (countTowardsTutorial) {
        //   console.log(this.state.tutorialState.curDomainHoverCount + 1);
        // }

        let activeTutorial = this.state.tutorialState.active;
        if (!activeTutorial) {
          if (activateExtensionInfoTutorial) {
            activeTutorial = "extensionInfo";
          } else if (activateFavoritesTutorial) {
            activeTutorial = "favorites";
          } else if (activateNewsletterSignupTutorial) {
            activeTutorial = "newsletterSignup";
          }
        }

        const tutorialState = {
          ...this.state.tutorialState,
          curDomainHoverCount: countTowardsTutorial
            ? this.state.tutorialState.curDomainHoverCount + 1
            : this.state.tutorialState.curDomainHoverCount,
          active: activeTutorial,
        };
        if (activateExtensionInfoTutorial) {
          localStorage.setItem("seenExtensionInfoTutorial", "true");
        } else if (activateFavoritesTutorial) {
          localStorage.setItem("seenFavoritesTutorial", "true");
        } else if (activateNewsletterSignupTutorial) {
          localStorage.setItem("seenNewsletterSignupTutorial", "true");
        }

        this.setState({
          deepChecked: {
            ...this.state.deepChecked,
            [domain]: true,
          },
          lastResults: {
            ...this.state.lastResults,
            taken: {
              ...this.state.lastResults.taken,
              [extension]: data.taken,
            },
            updated: {
              ...this.state.lastResults.updated,
              [extension]:
                this.state.lastResults.updated[extension] || data.created,
            },
            premiumInfo: {
              ...this.state.lastResults.premiumInfo,
              [extension]: {
                isPremium: data.isPremium,
                prices: data.prices,
              },
            },
          },
          tutorialState: tutorialState,
        });
      })
      .catch((err) => {
        console.error("Failed to deep query tld", extension, err);
        // still end the querying state
        this.setState({
          deepChecked: {
            ...this.state.deepChecked,
            [domain]: true,
          },
        });
      });
  }

  setFavoriteDomainState(domainToUpdate, newFavoriteState) {
    this.setState(
      (state) => {
        const otherStates = state.favoriteDomains.filter(
          (domain) => domain !== domainToUpdate
        );
        if (newFavoriteState) {
          otherStates.push(domainToUpdate);
        }

        return {
          favoriteDomains: otherStates,
        };
      },
      () =>
        localStorage.setItem(
          "favoriteDomains",
          JSON.stringify(this.state.favoriteDomains)
        )
    );
  }

  setCardTrackedActiveState(extension, state) {
    this.activeCardExt = state ? extension : null;
  }

  exitTutorial() {
    this.setState((prevState) => {
      // if (
      //   prevState.tutorialState.active === "extensionInfo" &&
      //   prevState.tutorialState.extensionInfo.step === 1
      // ) {
      //   return {
      //     tutorialState: {
      //       ...prevState.tutorialState,
      //       extensionInfo: {
      //         step: 2,
      //       },
      //     },
      //   };
      // }
      return {
        tutorialState: {
          ...prevState.tutorialState,
          [prevState.tutorialState.active]: {
            alreadyShowed: true,
          },
          active: null,
        },
      };
    });
  }

  async fetchPopularDomainState() {
    const allData = await api.getPopularDomains("*");
    this.setState({ popularDomainsState: allData });
  }

  render() {
    return (
      <div className={["App", styles.app].join(" ")}>
        {/* {this.state.tutorialState.active === "extensionInfo" &&
          this.state.tutorialState.extensionInfo.step === 2 && (
            <div
              className={styles.tutorialBackground}
              onClick={this.exitTutorial.bind(this)}
            ></div>
          )} */}
        {this.state.tutorialState.active === "newsletterSignup" && (
          <NewsletterTutorial exitTutorial={this.exitTutorial.bind(this)} />
        )}

        <Searchbar
          term={this.state.search}
          onSearch={this.onSearch.bind(this)}
          showHome={this.props.showHome}
          favoriteDomains={this.state.favoriteDomains}
          setDomainFavoriteState={this.setFavoriteDomainState.bind(this)}
          tutorialState={this.state.tutorialState}
          numTakenForActiveQuery={this.state.lastResults.numTaken}
        />
        <div className={styles.spacer} />
        <AutoSizer>
          {({ height, width }) => (
            <List
              height={height - 62}
              width={width}
              itemCount={groups.length + 2} // include suggestions row & last padding element
              itemSize={(index) =>
                this.getRowHeights(document.documentElement.clientWidth)[index]
              }
              itemData={this.createItemData(
                this.state.lastResults,
                this.state.deepChecked,
                this.state.name,
                this.state.tld,
                this.state.favoriteDomains,
                this.state.tutorialState,
                this.state.popularDomainsState
              )}
              ref={this.domainGroupListRef}
              style={
                this.state.tutorialState.active ? { overflowY: "hidden" } : {}
              }
            >
              {this.DomainGroupRowComp}
            </List>
          )}
        </AutoSizer>
        {this.state.tld && (
          <Helmet
            activeTld={this.state.tld}
            popularExample={
              this.state.popularDomainsState?.[this.state.tld]?.domains?.[0]
                ?.domain
            }
          />
        )}
      </div>
    );
  }

  createItemData = (
    lastResults,
    deepChecked,
    name,
    tld,
    favoriteDomains,
    tutorialState,
    popularDomainsState
  ) => ({
    lastResults: lastResults,
    deepChecked: deepChecked,
    name: name,
    tld: tld,
    favoriteDomains: favoriteDomains,
    tutorialState: tutorialState,
    popularDomainsState,
  });

  DomainGroupRowComp = this.DomainGroupRow.bind(this);

  DomainGroupRow({ index, style, data }) {
    if (index - 1 === groups.length) {
      // last padding element
      return <div style={style} />;
    }
    const {
      lastResults,
      deepChecked,
      name,
      tld,
      favoriteDomains,
      tutorialState,
      popularDomainsState,
    } = data;

    if (index === 0) {
      return (
        <SmartDomainGroup
          key="suggestions"
          // taken={lastResults.taken}
          updatedDates={lastResults.updated}
          premiumInfo={lastResults.premiumInfo || {}}
          setCardTrackedActiveState={this.setCardTrackedActiveState.bind(this)}
          deepQueried={deepChecked}
          search={this.state.name}
          preciseQueryTld={tld}
          deepCheckDomain={this.deepCheckCurrentExtension.bind(this)}
          favoriteDomains={favoriteDomains}
          setDomainFavoriteState={this.setFavoriteDomainState.bind(this)}
          tutorialState={tutorialState}
          exitTutorial={this.exitTutorial.bind(this)}
          popularDomainsState={popularDomainsState}
          maxNumOfResults={this.getDomainsPerRow(
            document.documentElement.clientWidth
          )}
        />
      );
    }

    const { title, tlds } = groups[index - 1];
    return (
      <div className={styles.lazyloadRow} style={style}>
        <DomainGroup
          key={title}
          title={title}
          extensions={tlds}
          taken={lastResults.taken}
          updatedDates={lastResults.updated}
          premiumInfo={lastResults.premiumInfo || {}}
          setCardTrackedActiveState={this.setCardTrackedActiveState.bind(this)}
          deepQueried={deepChecked}
          search={name}
          preciseQueryTld={tld}
          deepCheckDomain={this.deepCheckCurrentExtension.bind(this)}
          favoriteDomains={favoriteDomains}
          setDomainFavoriteState={this.setFavoriteDomainState.bind(this)}
          tutorialState={tutorialState}
          exitTutorial={this.exitTutorial.bind(this)}
          popularDomainsState={popularDomainsState}
        />
      </div>
    );
  }

  getDomainsPerRow = memoize((windowWidth) => {
    let domainWidth, windowSideMarginPercentage, domainGroupIndent;
    if (windowWidth > 1300) {
      domainWidth = 120 + 12 + 6 + 12 * 2;
      windowSideMarginPercentage = 0.1;
      domainGroupIndent = 36;
    } else if (windowWidth > 700) {
      domainWidth = 96 + 12 + 6 + 12 * 2;
      windowSideMarginPercentage = 0.05;
      domainGroupIndent = 36;
    } else {
      domainWidth = 60 + 6 + 6 + 6 * 2;
      windowSideMarginPercentage = 0;
      domainGroupIndent = 0;
    }

    const usageDomainWidth =
      windowWidth * (1 - windowSideMarginPercentage * 2) - domainGroupIndent;
    const domainsPerRow = Math.floor(usageDomainWidth / domainWidth);

    return domainsPerRow;
  });

  getRowHeights = memoize((windowWidth) => {
    let title, domainHeight, paddingHeight;
    if (windowWidth > 1300) {
      title = 30 + 12 * 2 + 5;
      domainHeight = 25 + 4 * 2 + 12 * 2;
      paddingHeight = 15 * 2 + 15;
    } else if (windowWidth > 700) {
      title = 26 + 8 * 2 + 5;
      domainHeight = 20 + 4 * 2 + 12 * 2;
      paddingHeight = 10 * 2;
    } else {
      title = 26 + 8 * 2 + 5;
      domainHeight = 20 + 4 * 2 + 6 * 2;
      paddingHeight = 10 * 2;
    }

    const rowHeights = Array.from(Array(groups.length).keys()).map((index) => {
      const domainsPerRow = this.getDomainsPerRow(windowWidth);
      return (
        title +
        Math.ceil(groups[index].tlds.length / domainsPerRow) * domainHeight +
        paddingHeight
      );
    });

    // include first suggestions row & final padding element
    return [title + domainHeight + paddingHeight, ...rowHeights, 300];
  });
}

export default SearchPage;
