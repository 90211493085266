import React from "react";

import { ReactComponent as Arrow } from "../../../icons/tutorial-arrow.svg";
import { ReactComponent as MobileArrow } from "../../../icons/tutorial-arrow-mobile.svg";
import styles from "./FavouritesTutorial.module.scss";

export default function FavouritesTutorial({
  isStep1,
  exitTutorial,
  rightCardSpace,
}) {
  const windowWidth = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  // whether to show caption to right or left
  let cardToLeft;
  if (windowWidth >= 850) {
    cardToLeft = rightCardSpace < 750;
  } else {
    // small viewport
    cardToLeft = rightCardSpace < 280;
  }

  return (
    <div
      className={[
        styles.favoritesTutorial,
        styles.active,
        cardToLeft ? styles.cardToLeft : "",
      ].join(" ")}
    >
      <div className={styles.text}>Save a few names before deciding</div>
      <Arrow className={styles.arrow} />
      <MobileArrow className={styles.mobileArrow} />
      <div
        className={styles.background}
        onClick={(e) => exitTutorial(e, true)}
      ></div>
    </div>
  );
}
