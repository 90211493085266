import React from "react";

import { ReactComponent as Arrow } from "../../../icons/tutorial-arrow.svg";
import { ReactComponent as MobileArrow } from "../../../icons/tutorial-arrow-mobile.svg";
import styles from "./ExtensionInfoTutorial.module.scss";

export default function ExtensionInfoTutorial({
  isStep1,
  exitTutorial,
  rightCardSpace,
}) {
  const windowWidth = Math.max(
    document.documentElement.clientWidth || 0,
    window.innerWidth || 0
  );

  // whether to show caption to right or left
  let cardToLeft;
  if (windowWidth >= 850) {
    cardToLeft = rightCardSpace < 750;
  } else {
    // small viewport
    cardToLeft = rightCardSpace < 280;
  }

  function exitTutorialInternal(event) {
    // if (!isStep1) {
    //   exitTutorial(event, true);
    // }
    event.stopPropagation();
  }

  // if (isStep1) {
  return (
    <div
      className={[
        styles.favoritesTutorial,
        styles.active,
        cardToLeft ? styles.cardToLeft : "",
      ].join(" ")}
      onClick={exitTutorialInternal}
    >
      <div className={styles.text}>
        <div>
          click the extension <br className={styles.mobileTextBreak} />
          for more details
        </div>
      </div>
      <Arrow className={styles.arrow} />
      <MobileArrow className={styles.mobileArrow} />
      <div className={styles.background}></div>
    </div>
  );
  // } else {
  //   return (
  //     <div
  //       className={[styles.favoritesTutorial, styles.step2, styles.active].join(
  //         " "
  //       )}
  //     >
  //       <div className={styles.text}>or show only extension details</div>
  //       <Arrow className={styles.arrow} />
  //       <MobileArrow className={styles.mobileArrow} />
  //       {/* background is shown from search page placeholder to highlight card */}
  //     </div>
  //   );
  // }
}
