/* global plausible */
import React from "react";

import styles from "./Card.module.scss";
import pricing_data from "../../../../src/data/pricing.json";

export default function AvailableContent({
  domain,
  premiumInfo,
  className = "",
  tld,
}) {
  return (
    <div className={[styles.priceTable, className].join(" ")}>
      {pricing_data.tlds[tld]?.map(({ registrar, register, renew }) => {
        const registrarData = pricing_data.registries[registrar];
        const registrarName = registrarData.name;
        const registrarLink = registrarData.link;

        const premiumRegisterInfo =
          premiumInfo?.prices?.registries?.[registrarName.toLowerCase()] ||
          premiumInfo?.prices?.base;

        // initial premium status may be inaccurate due to previous bugs
        // only show premium status when we have price info
        const showPremiumStatus = premiumInfo?.isPremium && premiumInfo?.prices;

        return (
          <React.Fragment key={registrarName}>
            <div
              className={[
                styles.registrarCell,
                styles.cost,
                styles.register,
                showPremiumStatus ? styles.premium : "",
              ].join(" ")}
              title="1st year price"
            >
              ${Math.round(premiumRegisterInfo?.registerPrice || register)}
            </div>
            <div
              className={[
                styles.registrarCell,
                styles.cost,
                styles.renew,
                showPremiumStatus ? styles.premium : "",
              ].join(" ")}
              title="2nd year price"
            >
              ${Math.round(premiumRegisterInfo?.renewalPrice || renew)}
            </div>
            <a
              href={registrarLink.replace("{domain}", domain)}
              target="_blank"
              rel="noopener noreferrer"
              className={[
                styles.registrarCell,
                styles.registar,
                showPremiumStatus ? styles.premium : "",
              ].join(" ")}
              onClick={() => {
                try {
                  plausible("registryOutboundClick", {
                    props: { registry: registrarName, extension: tld },
                  });
                } catch {}
              }}
              title="domain registrar"
            >
              {registrarName}
            </a>
          </React.Fragment>
        );
      })}
    </div>
  );
}
