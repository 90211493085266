/* global plausible */
import React, { useState, useEffect } from "react";

import styles from "./Searchbar.module.scss";
import FavoritesWindow from "../FavoritesWindow/FavoritesWindow";
import NameStats from "../NameStats/NameStats";
// import ExtensionInfoTutorial from "../../../pages/SearchPage/Tutorial/ExtensionInfoTutorial";

import { ReactComponent as FilledStar } from "../../../icons/star-solid.svg";
import { ReactComponent as CompactLogo } from "../../../icons/logo_with_title_compact_nofont.svg";
import { ReactComponent as LogoIcon } from "../../../icons/logo.svg";
import { ReactComponent as CrossIcon } from "../../../icons/times-solid.svg";

function Searchbar({
  term,
  onSearch,
  showHome,
  favoriteDomains,
  setDomainFavoriteState,
  tutorialState,
  numTakenForActiveQuery,
}) {
  const [showFavorites, setShowFavorites] = useState(false);
  if (favoriteDomains.length === 0 && showFavorites) {
    setShowFavorites(false);
  }

  useEffect(() => {
    if (showFavorites) {
      try {
        plausible("checkFavorites", {
          props: { numFavorites: favoriteDomains.length },
        });
      } catch {}
    }
  }, [showFavorites, favoriteDomains]);

  // not compely accurate for hybrid devices
  var supportsTouch = "ontouchstart" in window || navigator.msMaxTouchPoints;

  // const extensionInfoStep2Active =
  //   tutorialState.active === "extensionInfo" &&
  //   tutorialState.extensionInfo.step === 2;

  return (
    <>
      <div className={styles.root}>
        {tutorialState.active && <div className={styles.tutorialBackground} />}
        <div className={styles.left}>
          <CompactLogo className={styles.logo} alt="logo" onClick={showHome} />
          <LogoIcon className={styles.logoIcon} alt="logo" onClick={showHome} />
        </div>
        <div className={styles.middle}>
          <input
            className={styles.search}
            placeholder="newdomain.name"
            value={term}
            onChange={(event) => onSearch(event.target.value.toLowerCase())}
            autoFocus
            type="text"
            autoComplete="off"
            autoCorrect="off"
            autoCapitalize="off"
            spellCheck="false"
          />
          <CrossIcon
            className={[
              styles.clearSearchButton,
              // extensionInfoStep2Active ? styles.tutorialActive : "",
            ].join(" ")}
            onClick={() => onSearch("")}
          />
          {/* {extensionInfoStep2Active && (
            <ExtensionInfoTutorial
              // exitTutorial={exitTutorial}
              // rightCardSpace={rightCardSpace}
              isStep1={false}
            />
          )} */}
        </div>
        <div className={styles.right}>
          <NameStats numTakenForActiveQuery={numTakenForActiveQuery} />
          <div
            className={styles.favorites}
            onMouseEnter={() => {
              if (!supportsTouch) {
                // handle in onClick() triggered after this, to allow toggling window state
                setShowFavorites(true);
              }
            }}
            onClick={() => {
              setShowFavorites(!showFavorites);
            }}
          >
            {/* flexbox reverse-row direction */}
            <div className={styles.text}>
              <span>{favoriteDomains.length}</span>
            </div>
            <FilledStar
              id="star"
              className={[styles.star, styles.isFavorite].join(" ")}
            />
          </div>
        </div>
      </div>

      <FavoritesWindow
        className={
          showFavorites
            ? styles.openFavoritesWindow
            : styles.closedFavoritesWindow
        }
        favoriteDomains={favoriteDomains}
        setDomainFavoriteState={setDomainFavoriteState}
        onMouseLeave={() => {
          setShowFavorites(false);
        }}
      />
    </>
  );
}

export default Searchbar;
