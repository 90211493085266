import React from "react";
import { ResponsiveContainer, AreaChart, Area } from "recharts";
import punycode from "punycode";

// import * as api from "../../../api";
import styles from "./ExtensionInfoContent.module.scss";

// const allPopularDomains = require("../../../data/popular_domains.json");
// const allGraphData = require("../../../data/domainCounts_all.json");

const defaultExtensionState = {
  domains: null,
  stats: null,
};

export default function ExtensionInfoContent({
  tld,
  forceWidth,
  popularDomainsState: extensionData = defaultExtensionState,
}) {
  // const [extensionData, setExtensionData] = useState(
  //   popularDomainsState || {
  //     domains: null,
  //     stats: null,
  //   }
  // );
  // useEffect(() => {
  //   async function fetchDomains() {
  //     setExtensionData({
  //       ...(await api.getPopularDomains(tld)),
  //       fetched: true,
  //     });
  //   }
  //   if (!extensionData.fetched) {
  //     fetchDomains();
  //   }
  // });

  // todo handle premium widths
  if (window.innerWidth < 1000) {
    forceWidth = 230;
  } else {
    forceWidth = 300;
  }

  return (
    <div className={[styles.root].join(" ")}>
      <div className={styles.chart}>
        {extensionData?.stats?.history ? (
          <ResponsiveContainer
            width={forceWidth + 40} // ignore padding
            height="100%"
          >
            <AreaChart
              data={extensionData?.stats?.history}
              margin={{
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
              }}
            >
              <Area
                isAnimationActive={false}
                type="monotone"
                dataKey="count"
                stroke="#D9E2EC"
                fillOpacity={1}
                fill="#D9E2EC"
              />
            </AreaChart>
          </ResponsiveContainer>
        ) : (
          <div className={styles.chartPlaceHolder} style={{ height: 300 }} />
        )}
      </div>

      {extensionData?.stats?.count && (
        <div className={styles.domainCount}>
          <div className={styles.text}>
            {extensionData.stats.count} domains
            {(extensionData.stats?.change?.state === "+" ||
              extensionData.stats?.change?.state === "~") && (
              <div className={styles.domainAdditions}>
                +{extensionData.stats.change.count} per day
              </div>
            )}
            {extensionData.stats?.change?.state === "-" && (
              <div className={styles.domainRemovals}>
                {extensionData.stats.change.count} per day
              </div>
            )}
          </div>
        </div>
      )}

      {/* <div className={styles.description}>
        Cool names for experiments or modern brands.
      </div> */}

      <div className={styles.bottomContent}>
        <div
          className={[
            styles.popularDomains,
            !extensionData.domains ? styles.notFetchedYet : "",
          ].join(" ")}
          style={{ width: `${forceWidth}px` }}
        >
          <div className={styles.table}>
            {!!extensionData.domains &&
              extensionData.domains.slice(0, 5).map((domainInfo) => (
                <React.Fragment key={domainInfo.domain}>
                  <img
                    className={styles.icon}
                    src={domainInfo.favicon}
                    alt=""
                  />
                  <a
                    href={"http://" + domainInfo.domain} // not all websites handle https?
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.domain}
                    title={domainInfo.tagline}
                  >
                    {domainInfo.domain.indexOf("xn--") !== -1
                      ? punycode.toUnicode(domainInfo.domain)
                      : domainInfo.domain}
                  </a>
                  <div className={styles.pageHits} title="Monthly Page Visits">
                    {domainInfo.pageHits}
                  </div>
                </React.Fragment>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
}
