import axios from "axios";

const apiStage = "v2";
const endpoints = [
  `https://rs4nfbx77h.execute-api.us-west-2.amazonaws.com/v2`,
  // `https://apis.domain.garden/${apiStage}`,
  // `https://api-us.tlds.cool/${apiStage}`,
  // `https://api-eu.tlds.cool/${apiStage}`,
  // `https://api-ap.tlds.cool/${apiStage}`,
];

let fastestEndpoint = endpoints[0];
export async function speedtest() {
  fastestEndpoint = await Promise.race(
    endpoints.map((url) =>
      axios.get(url + "/avail?base=speedtestapideployments").then(() => url)
    )
  );
}

export function requestAvail(domain) {
  return axios.get(fastestEndpoint + "/avail?base=" + domain);
}

export function deepCheckDomain(base, tld) {
  return new Promise((resolve, reject) => {
    axios
      .post(fastestEndpoint + "/ondemand-check", { base, tld })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch(reject);
  });
}

export function getPopularDomains(tld) {
  return new Promise((resolve, reject) => {
    axios
      .get(fastestEndpoint + "/popular-domains", {
        params: {
          tld,
        },
      })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch(reject);
  });
}

export function getSuggestedDomains(query) {
  return new Promise((resolve, reject) => {
    axios
      .get(fastestEndpoint + "/suggested-domains", {
        params: {
          query,
        },
      })
      .then((resp) => {
        resolve(resp.data);
      })
      .catch(reject);
  });
}
